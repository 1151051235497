<template>
	<v-container>
		<v-row align="center" justify="center">
			<v-col cols="12">
				<slot />
			</v-col>
			<v-container id="o-mnie" tag="section">
				<base-subheading class="text-center">Moje Książki</base-subheading>
				<v-divider></v-divider>
			</v-container>
			<feed-card v-for="(product, i) in paginatedProducts" :key="product.title" :size="layout[i]" :id="product.koszykID" :isLoading="isLoading" />
		</v-row>
		<v-row align="center">
			<v-col cols="3">
				<base-btn v-if="page !== 1" class="ml-0" square title="Previous page" @click="page--">
					<v-icon>mdi-chevron-left</v-icon>
				</base-btn>
			</v-col>
			<v-col v-if="page > 1" class="text-center subheading" cols="6"> STRONA {{ page }} z {{ pages }} </v-col>
			<v-col class="text-right" cols="3">
				<base-btn v-if="pages > 1 && page < pages" class="mr-0" square title="Next page" @click="page++">
					<v-icon>mdi-chevron-right</v-icon>
				</base-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "Feed",

	components: {
		FeedCard: () => import("@/components/FeedCard"),
	},

	data() {
		return {
			layout: [2, 2, 1, 2, 2, 3, 3, 3, 3, 3, 3],
			page: 1,
			airtableData: [],
			koszykData: [],
			products: [],
			isLoading: true,
		};
	},

	computed: {
		pages() {
			return Math.ceil(this.products.length / 11);
		},
		paginatedProducts() {
			const start = (this.page - 1) * 11;
			const stop = this.page * 11;
			return this.products.slice(start, stop);
		},
	},
	async mounted() {
		if (this.$store.state.products.length === 0) {
			this.isLoading = true;
			const { VUE_APP_API_URL } = process.env;
			await this.getDataFromAirtable(VUE_APP_API_URL);
			await this.getDataFromKoszykFunction(VUE_APP_API_URL);
			this.mergeProductData();
		}
		// Do not make another call if products are already in store
		else {
			this.products = this.$store.state.products;
			this.isLoading = false;
		}
	},
	methods: {
		async getDataFromAirtable(VUE_APP_API_URL) {
			const axios = require("axios");
			await axios
				.get(`.netlify/functions/AirtableFunction`)
				.then((response) => {
					this.airtableData = response.data;
					return this.airtableData;
				})
				.catch((err) => console.log(err));
		},
		async getDataFromKoszykFunction(VUE_APP_API_URL) {
			const axios = require("axios");

			await axios
				.get(`.netlify/functions/KoszykFunction`)
				.then((response) => {
					this.koszykData = response.data;
					return this.koszykData;
				})
				.catch((err) => console.log(err));
		},
		mergeProductData() {
			if (this.koszykData != null || this.koszykData.count > 0) {
				this.products = this.koszykData.map((item) => {
					const obj = this.airtableData.find((o) => o.koszykID === item.koszykID);
					return { ...item, ...obj };
				});
				this.products = this.products.sort(function (a, b) {
					return a.order - b.order;
				});

				this.$store.state.products = this.products;
			}
			this.isLoading = false;
		},
	},
	watch: {
		page() {
			window.scrollTo(0, 0);
		},
	},
};
</script>
<style scoped>
.text-center {
	font: 40px Raleway;
}
</style>
